import './App.css';
import { Route, Routes } from 'react-router-dom';
import React, { useEffect, useContext } from 'react';
import Home from './pages/Home';
import Hospedajes from './pages/Hospedajes';
import Login from './pages/Login';
import Comercio from './pages/Comercio';
import PrivateRoute from './pages/PrivateRoute';
import EditPromotion from './pages/EditPromotion';
import DetalleHospedaje from './pages/DetalleHospedaje';
import Restaurants from './pages/Restaurantes';
import Delivery from './pages/Delivery';
import Events from './pages/Events';
import Shops from './pages/Shops';
import EventDetail from './pages/EventDetail';
import ShopSearch from './pages/ShopSearch';
import ServicesPage from './pages/ServicesPage.js';
import PrivacyPolicy from './pages/PoliticaPrivacidad';
import { getToken, onMessage } from "firebase/messaging";
import { vapidKey } from './config.ts';
import { signInAnonymously } from "firebase/auth";
import { auth, messaging } from './firebase';
import { AuthContext } from './AuthContext';
import { firebaseAnalytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import { useLocation } from 'react-router-dom';

const App = () => {

  const { currentUser } = useContext(AuthContext);
  const currentLocation = useLocation();

  useEffect(() => {
    const authenticateAnonymously = async () => {
      if (!currentUser) {
        try {
          await signInAnonymously(auth);
          console.log("Usuario autenticado anónimamente.");
        } catch (error) {
          console.error("Error en la autenticación anónima:", error.message);
        }
      }
    };
    authenticateAnonymously();
  }, [currentUser]);

  
  async function requestPermission() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey: vapidKey,
        });
        console.log("Token generado: ", token);
      } catch (error) {
        console.error("Error al obtener el token: ", error);
      }
    } else if (permission === "denied") {
      alert("Has denegado las notificaciones");
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.onBackgroundMessage` handler.
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Update the UI to include the received message.
     
  });

  useEffect(() => {
    // Registrar vistas de página en Firebase Analytics
    logEvent(firebaseAnalytics, 'page_view', {
      page_path: currentLocation.pathname, // Usar currentLocation en lugar de location
    });
    console.log("Se registró la vista de página:", currentLocation.pathname);
  }, [currentLocation]); // Escuchar cambios en currentLocation


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/hospedajes" element={<Hospedajes />} />
      <Route path="/hospedaje/:name" element={<DetalleHospedaje />} />
      <Route path='/restaurant' element={<Restaurants />} />
      <Route path='/delivery' element={<Delivery />} />
      <Route path='/comercio/:name' element={<Shops />} />
      <Route path='/buscador' element={<ShopSearch />} />
      <Route path='/events' element={<Events />} />
      <Route path='/politica-privacidad' element={<PrivacyPolicy />} />
      <Route path='/evento/:name' element={<EventDetail />} />
      <Route path="/login" element={<Login />} />
      <Route path="/edit-promotion/:promotionId" element={<EditPromotion />} />
      <Route path='/servicios' element={<ServicesPage/>}/>
      <Route path="/comercio" element={
        <PrivateRoute>
          <Comercio />
        </PrivateRoute>
      } />
    </Routes>
  );
}

export default App;