import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { database } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './Hospedajes.css';
import ShimmerCard from '../ShimmerCard';


function Delivery() {
  const [delivery, setDelivery] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchDelivery = async () => {
      try {
        const deliveryRef = ref(database, 'delivery');
        const snapshot = await get(deliveryRef);
        if (snapshot.exists()) {
          const deliveryArray = Object.keys(snapshot.val()).map(key => ({
            id: key,
            ...snapshot.val()[key]
          }));
          setDelivery(deliveryArray);
        }
      } catch (error) {
        console.error("Error al recuperar los delivery:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDelivery();
  }, []);

  const handleCardClick = (delivery) => {
    navigate(`/comercio/${delivery.name}`, { state: delivery });
  };

  return (
    <div className="hospedajes-container">
      {loading ? (
        Array.from({ length: 8 }).map((_, index) => <ShimmerCard key={index} />)
      ) : (
        delivery
          .filter((item) => item.showOnList) // Filtrar los que tienen showOnList en true
          .map((delivery) => (
            <div key={delivery.id} className="card" onClick={() => handleCardClick(delivery)}>
              <img src={delivery.logo} alt={delivery.name} className="card-logo" />
              <h3 className="card-name">{delivery.name}</h3>
            </div>
          ))
      )}
    </div>
  );
}

export default Delivery;
