import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import './UpcomingEvents.css';

const UpcomingEvents = ({ events }) => {
  if (!events || events.length === 0) {
    return null;
  }

  // Formatear y ordenar eventos por fecha más próxima
  const sortedEvents = events
    .map((event) => ({
      ...event,
      closestDate: Math.min(...event.dateList.map(d => d.date)), // Extraer el timestamp correctamente
    }))
    .sort((a, b) => a.closestDate - b.closestDate);

  return (
    <Box sx={{ overflowX: "hidden", padding: "16px" }}>
      <Typography variant="h6" sx={{ textAlign: "left", marginBottom: "8px", fontSize: "18px", fontWeight: "bold" }}>
        Próximos eventos
      </Typography>

      <Box className="scroll-container">
        {sortedEvents.map((event, index) => (
          <EventCard
            key={event.id || index}
            event={event}
            formattedDates={event.dateList.map(d => formatDate(d.date)).join(", ")} // Convertir fechas a dd/MM
          />
        ))}
      </Box>
    </Box>
  );
};

const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString("es-ES", {
    day: "numeric",
    month: "short",
  });
};

const EventCard = ({ event, formattedDates }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/evento/${event.name}`, { state: event });
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        minWidth: { xs: '60%', sm: '30%' },
        marginX: "8px",
        marginBottom: "10px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        overflow: "hidden",
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
        }
      }}
    >
      <img
        src={event.flyer}
        alt={event.title}
        style={{
          width: "100%",
          height: "150px",
          objectFit: "cover",
        }}
      />
      <Box sx={{ padding: "8px" }}>
      <Typography
          variant="body1"
          sx={{
            textAlign: "left",
            fontWeight: "bold",
            whiteSpace: "nowrap", // Evita que el texto se divida en varias líneas
            overflow: "hidden", // Oculta el texto que sobrepasa el ancho del contenedor
            textOverflow: "ellipsis", // Agrega los puntos suspensivos "..."
          }}
        >
          {event.name}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "left", color: "#666" }}>
          📅 {formattedDates} {/* Mostrar fechas convertidas */}
        </Typography>
      </Box>
    </Box>
  );
};

export default UpcomingEvents;