import { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { database } from '../firebase';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import SearchBar from './/components/SearchBar'; 
import { Box, Skeleton, Grid } from '@mui/material';

function ShopSearch() {
    const [chips, setChips] = useState([]);
    const [initialMarkets, setMarkets] = useState([]); // Cambiar esto a un estado independiente
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMarkets, setFilteredMarkets] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const chipsRef = ref(database, 'chips');
                const chipsSnapshot = await get(chipsRef);

                if (chipsSnapshot.exists()) {
                    const chipsArray = Object.keys(chipsSnapshot.val()).map(key => ({
                        id: key,
                        ...chipsSnapshot.val()[key]
                    }));
                    setChips(chipsArray);

                    const marketsRef = ref(database, 'markets');
                    const marketsSnapshot = await get(marketsRef);

                    if (marketsSnapshot.exists()) {
                        const marketsArray = Object.keys(marketsSnapshot.val()).map(key => ({
                            id: key,
                            ...marketsSnapshot.val()[key]
                        }));
                        setMarkets(marketsArray);
                        setFilteredMarkets(marketsArray); // Inicializa el filtro con todos los mercados
                    }
                } else {
                    console.log("No hay chips disponibles");
                }
            } catch (error) {
                console.error("Error al recuperar los datos:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
    
        // Filtra los mercados basado en el texto de búsqueda
        const filtered = initialMarkets.filter(market => {
            const queries = market.queryes ? market.queryes.toLowerCase() : "";
            return queries.includes(newSearchTerm.trim().toLowerCase());
        });
    
        setFilteredMarkets(filtered);
    };

    const handleChipClick = (chip) => {
        const labelText = chip.label.split(" ")[0];
        handleSearchChange(labelText);  // Realiza la búsqueda con el texto del chip
    };
    
    if (loading) {
        return (
            <Box sx={{ width: '90%', maxWidth: { xs: '100%', sm: '80%', md: '60%' }, marginX: "auto", padding: "20px" }}>
                {/* Skeleton para la barra de búsqueda */}
                <Skeleton variant="rectangular" height={40} sx={{ marginBottom: "16px", borderRadius: "8px" }} />
                
                {/* Skeleton para los chips */}
                <Grid container spacing={1}>
                    {Array.from({ length: 30 }).map((_, index) => (
                        <Grid item key={index}>
                            <Skeleton
                                variant="rectangular"
                                height={32}
                                width={`${Math.floor(Math.random() * 50) + 70}px`} // Tamaño variable entre 70px y 120px
                                sx={{ borderRadius: "16px" }} // Bordes redondeados
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    return (
        <div className="app">
            <SearchBar
                placeholder="Buscar comercios"
                data={filteredMarkets}
                searchTerm={searchTerm}  // Pasamos el searchTerm al SearchBar
                onSearchChange={handleSearchChange}
                dataType="comercio" 
            />
            <div className="category-grid">
                {chips.map((chip) => (
                    <CategoryButton key={chip.id} label={chip.label} onClick={() => handleChipClick(chip)} />
                ))}
            </div>
        </div>
    );
}

function CategoryButton({ label, onClick }) {
    return <button className="category-button" onClick={onClick}>{label}</button>;
}

export default ShopSearch;
