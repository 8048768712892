import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./SearchBar.css";
import { Typography } from "@mui/material";
import { Card, CardContent } from '@mui/material';
import { MapPin, Phone } from 'lucide-react';

function SearchBar({ placeholder, data, searchTerm, onSearchChange, dataType }) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState(searchTerm || "");
    const navigate = useNavigate();

    useEffect(() => {
        setWordEntered(searchTerm || "");
    }, [searchTerm]);

    useEffect(() => {
        if (wordEntered) handleFilter(wordEntered);
    }, [wordEntered]);

    const handleFilter = (searchTerm) => {
        const searchQuery = typeof searchTerm === "string" ? searchTerm.trim().toLowerCase() : "";

        if (!searchQuery) {
            setFilteredData([]);
            return;
        }

        let filtered;
        if (dataType === 'comercio') {
            filtered = data.filter(market => {
                const queries = market.queryes ? market.queryes.toLowerCase() : "";
                return queries.includes(searchQuery);
            });
        } else if (dataType === 'servicio') {
            filtered = data.filter(service =>
                service.queryes.toLowerCase().includes(searchQuery.toLowerCase()) ||
                service.professionalTitle.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filtered);
        onSearchChange(searchTerm);
    };

    const handleInputChange = (event) => {
        const newSearchTerm = event.target.value;
        setWordEntered(newSearchTerm);
        onSearchChange(newSearchTerm);
    };

    const clearInput = () => {
        setWordEntered("");
        onSearchChange("");
        setFilteredData([]);
    };

    return (
        <div className="search">
            <div className="searchInputs">
                <input
                    type="text"
                    placeholder={placeholder}
                    value={wordEntered}
                    onChange={handleInputChange}
                />
                <div className="searchIcon">
                    {wordEntered.length === 0 ? (
                        <span>🔍</span>
                    ) : (
                        <span onClick={clearInput}>❌</span>
                    )}
                </div>
            </div>
    
            {filteredData.length !== 0 && (
                <div className="dataResult">
                    {filteredData.slice(0, 15).map((value, key) => {
                        const today = new Date().getDay(); // Obtiene el día actual (0 para domingo)
                        const todaySchedule = value.scheduleDays?.[today]?.day || "Cerrado";
                        const [morning, afternoon] = todaySchedule.split("|");

                        const handleItemClick = () => {
                            if (dataType === 'comercio') {
                                navigate(`/comercio/${value.name}`, { state: value });
                            }
                            // Si es 'servicio', no hace nada
                        };
                        
    
                        return (
                            <div key={key} className="dataItem" style={{ cursor: 'pointer' }} onClick={handleItemClick}>
                                {/* Si es un servicio, mostramos la tarjeta con la información */}
                                {dataType === 'servicio' ? (
                                   <CardContent>
                                   {/* Logo */}
                                   <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                       <img
                                           src={value.photoProfile}
                                           alt={`${value.name} Logo`}
                                           style={{
                                               width: 80,
                                               height: 80,
                                               borderRadius: '20%',
                                               marginRight: 10,
                                           }}
                                       />
                                       {/* Nombre */}
                                       <Typography>
                                           <p className="marketName" style={{ display: 'inline', margin: 0, fontSize: '1.2rem', fontWeight: 'bold' }}>
                                               {value.name}
                                           </p>
                                       </Typography>
                                       <Typography>
                                           <p className="marketName" style={{ display: 'inline', margin: 4, fontSize: '1.2rem', fontWeight: 'bold' }}>
                                               {value.lastName}
                                           </p>
                                       </Typography>
                                   </div>
                               
                                   {/* Specialty */}
                                   <p className="text-sm text-gray-600 font-medium" style={{ paddingBottom: 10 }}>
                                       {value.specialty}
                                   </p>
                               
                                   {/* Description */}
                                   <Typography style={{ paddingBottom: 10 }}>
                                       <p className="mar" style={{ display: 'inline', margin: 2, fontSize: '0.9rem', paddingBottom: 10 }}>
                                           {value.description}
                                       </p>
                                   </Typography>
                               
                                   {/* Dirección (si existe) */}
                                   {value.address && value.address.trim() !== '' && (
                                       <div className="flex items-center gap-2 mt-2 text-sm text-blue-500" style={{ paddingTop: 5 }}>
                                           <MapPin size={16} style ={{paddingRight: 10}}/>
                                           <span>{value.address}</span>
                                       </div>
                                   )}
                               
                                   {/* Teléfono (si existe) */}
                                   {value.phone && value.phone.trim() !== '' && (
                                       <div className="flex items-center gap-2 mt-1 text-sm text-green-500" style={{ paddingTop: 5 }}>
                                           <Phone size={16}  style ={{paddingRight: 10}}/>
                                           <a
                                               href={`https://wa.me/${value.phone.replace(/\D/g, '')}`}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                           >
                                               {value.phone}
                                           </a>
                                       </div>
                                   )}
                               </CardContent>                               
                                ) : (
                                    // Contenedor para comercios
                                    <div key={key} className="dataItem" style={{ cursor: 'pointer' }}>
                                        {/* Contenedor para logo y nombre */}
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                            {/* Imagen circular del logo */}
                                            <img
                                                src={value.logo}
                                                alt={`${value.name} Logo`}
                                                style={{
                                                    width: 80,    // Ajusta el tamaño de la imagen
                                                    height: 60,   // Ajusta el tamaño de la imagen
                                                    borderRadius: '10%',  // Hace la imagen circular
                                                    marginRight: 10, // Espacio entre la imagen y el nombre
                                                }}  
                                            />            
                                            <Typography>
                                                <p className="marketName" style={{ display: 'inline', margin: 0 }}>{value.name}</p>
                                            </Typography>
                                        </div>
    
                                        {/* Sección de horarios de atención */}
                                        {todaySchedule === "Cerrado" ? (
                                            <p className="marketSchedule" style={{ color: 'red', marginBottom: 10 }}>Cerrado</p>
                                        ) : (
                                            <div className="marketSchedule" style={{ marginBottom: 10 }}>
                                                <Typography>Horarios de atención</Typography>
                                                <p>{morning}</p>
                                                <p>{afternoon}</p>
                                            </div>
                                        )}
    
                                        {/* Botón de acción */}
                                        <button
                                            style={{ paddingBottom: '10px' }}  // Aquí agregamos el padding-bottom
                                            onClick={handleItemClick}
                                            className="viewDetailsButton">
                                            Ver más
                                        </button>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
    
}

export default SearchBar;