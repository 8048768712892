import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import { ref, get } from "firebase/database";
import { AuthContext } from '../AuthContext';
import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import UpcomingEvents from '../UpcomingEvents';
import HeaderImage from '../HeaderImage';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AppPromotionPopup from './components/PromotionPopUp';
import { JoinLeft } from '@mui/icons-material';
import { auth, database } from '../firebase';


// Función para detectar si es móvil
const isMobile = window.innerWidth < 768;

const PharmacyCard = ({ pharmacy }) => {
  // Función para abrir Google Maps en la ubicación especificada
  const openGoogleMaps = () => {
    if (pharmacy.mapsLocation) {
      window.open(`https://www.google.com/maps?q=${encodeURIComponent(pharmacy.mapsLocation)}`, "_blank");
    }
  };

  // Función para abrir WhatsApp con el mensaje predefinido
  const openWhatsApp = () => {
    if (pharmacy.phone) {
      // Obtener el número de teléfono del alojamiento
      const phone = pharmacy.phone.replace(/\D/g, ''); // Eliminar caracteres no numéricos
      const message = encodeURIComponent("Hola! Me contacto desde Jardinfo");
      const whatsappUrl = `https://wa.me/${phone}?text=${message}`;
      window.open(whatsappUrl, "_blank");
    }
  };

  return (
    <Box
      sx={{
        width: '90%',
        maxWidth: { xs: '100%', sm: '80%', md: '60%' },
        marginX: "auto",
        marginBottom: "10px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <img
        src={pharmacy.logo}
        alt={pharmacy.name}
        style={{
          width: "100%",
          height: "180px",
          objectFit: "cover"
        }}
      />
      <Box sx={{ paddingLeft: "16px", paddingRight: '16px' }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'left', margin: '8px 0' }}>
          {pharmacy.name}
        </Typography>
        <div style={{ textAlign: 'left', margin: '0px 0' }}>
          {pharmacy.address}
          {pharmacy.showOnList && (
            <IconButton onClick={openGoogleMaps} color="primary">
              <LocationOnIcon />
            </IconButton>
          )}
        </div>
        <div style={{ textAlign: 'left', marginBottom: '8px 0' }}>
          {pharmacy.phone}
          {pharmacy.showOnList && pharmacy.hasWhatsappEnable && (
            <IconButton onClick={openWhatsApp} color="primary">
              <WhatsAppIcon />
            </IconButton>
          )}
        </div>
      </Box>


    </Box>
  );
};

const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const [headerData, setHeaderData] = useState(null);
  const [iconsData, setIconsData] = useState([]);
  const [dutyPharmacy, setDutyPharmacy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate(); // Usar navigate para redirigir
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');


  

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        setLoading(true);
        try {
          const pharmaciesRef = ref(database, 'pharmacy_on_duty');
          const snapshot = await get(pharmaciesRef);
          if (snapshot.exists()) {
            const pharmacyData = snapshot.val();
            checkDutyPharmacy(pharmacyData);
          }

          const headerRef = ref(database, 'header_image');
          const headerSnapshot = await get(headerRef);
          if (headerSnapshot.exists()) {
            const images = headerSnapshot.val();
            if (images.length > 0) {
              const randomIndex = Math.floor(Math.random() * images.length);
              const randomImage = images[randomIndex];
              setHeaderData(randomImage);
            }
          }

          const iconsRef = ref(database, 'home');
          const iconsSnapshot = await get(iconsRef);
          if (iconsSnapshot.exists()) {
            setIconsData(iconsSnapshot.val());
          }

          const eventsCollection = ref(database, 'events');
          const eventSnapshot = await get(eventsCollection);
          const allEvents = eventSnapshot.val() || [];

          const currentDate = Date.now() / 1000;
          const upcomingEvents = Object.values(allEvents).filter(event =>
            event.dateList.some(dateItem => dateItem.date > currentDate)
          );
          setEvents(upcomingEvents);
        } catch (error) {
          console.error("Error al recuperar los datos:", error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [currentUser]);

  const checkDutyPharmacy = (pharmacyData) => {
    const now = Math.floor(Date.now() / 1000);
    const dutyPharmacy = Object.values(pharmacyData).find(pharmacy =>
      Array.isArray(pharmacy.onDuty) &&
      pharmacy.onDuty.some(duty => now >= duty.startDate && now <= duty.endDate)
    );
    setDutyPharmacy(dutyPharmacy);
  };

  const handleIconClick = (iconKey) => {
    const routes = {
      hotels: '/hospedajes',
      restaurants: '/restaurant',
      delivery: '/delivery',
      events: '/events',
      markets: '/buscador',
      services:'/servicios'

      // Agrega más rutas según sea necesario
    };

    if (routes[iconKey]) {
      navigate(routes[iconKey]);
    }
  };

  return (
    <div className="app-container">
      <header className="header">
        {loading ? (
          <Skeleton
            variant="rectangular"
            width="100%" // Asegúrate de que el ancho sea el mismo que el de la imagen
            height={isMobile ? 250 : 300} // Ajusta la altura según el tamaño de la imagen que mostrarás
            style={{ margin: '0px', borderRadius: '8px' }} // Añade borderRadius para que coincida con la imagen
          />
        ) : headerData ? (
          <HeaderImage
            imageUrl={headerData.imageUrl}
            appName="Jardinfo"
            autor={headerData.autor}
            socialMediaUser={headerData.socialMediaUser}
            socialMediaUrl={headerData.socialMediaUrl}
          />
        ) : (
          <p>No hay datos disponibles para el encabezado.</p>
        )}
      </header>
      <div className="hospedajes-container">
        {loading ? (
          [...Array(8)].map((_, index) => (
            <div key={index} className="grid-item">
              <Skeleton variant="rectangular" width="100%" height={100} />
            </div>
          ))
        ) : (
          iconsData
            .filter(icon => icon.enable && icon.show && icon.show_web)
            .map(icon => (
              <div
                key={icon.key}
                className="grid-item"
                onClick={() => handleIconClick(icon.key)} // Manejar el clic en los íconos
                style={{ cursor: 'pointer' }} // Cambiar el cursor para indicar que es clickeable
              >
                <img src={icon.icon_url} alt={icon.name} />
                <p>{icon.name}</p>
              </div>
            ))
        )}
      </div>

      <div>
        <AppPromotionPopup></AppPromotionPopup>
        <Box display="flex" justifyContent="flex-start" alignItems="center" p={2} sx={{ marginBottom: '2px' }}>
          <Typography variant="h6" sx={{ textAlign: "left", marginRight: "10px", fontSize: "18px", fontWeight: "bold" }}>
            Farmacia de turno
          </Typography>
          <Tooltip
            title="Los turnos comienzan a las 8am y terminan a las 8am del día siguiente"
            arrow
            placement="top"
            disableHoverListener
            enterTouchDelay={0}
            leaveDelay={3000}
          >
            <IconButton sx={{ cursor: "pointer", padding: 0 }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={250} />
        ) : dutyPharmacy ? (
          <PharmacyCard pharmacy={dutyPharmacy} />
        ) : (
          <p>No hay farmacia de turno disponible.</p>
        )}
      </div>
      <UpcomingEvents events={events} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Duración en milisegundos
        onClose={() => setOpenSnackbar(false)} // Cierra el snackbar
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity="info"
          sx={{ width: '100%' }} // Ancho del snackbar
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Home;