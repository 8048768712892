import React, { useState, useEffect } from 'react';
import SearchBar from './components/SearchBar'; // Asegúrate de la ruta correcta
import { ref, get } from 'firebase/database';
import { database } from '../firebase';
import './services.css';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { Check } from 'lucide-react'
import { Typography } from '@mui/material';

const ServicesPage = () => {
  const [searchTerm, setSearchTerm] = useState(''); // Declaramos searchTerm
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const servicesRef = ref(database, 'services');
        const servicesSnapshot = await get(servicesRef);

        if (servicesSnapshot.exists()) {
          const servicesArray = Object.keys(servicesSnapshot.val()).map((key) => ({
            id: key,
            ...servicesSnapshot.val()[key],
          }));
          setServices(servicesArray);
          setFilteredServices([]);
          setSuggestions(shuffleArray(servicesArray).slice(0, 7));
        } else {
          console.warn('No hay servicios disponibles en la base de datos.');
          setServices([]);
        }
      } catch (error) {
        console.error('Error al recuperar los datos de Firebase:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const handleSearchChange = (searchTerm) => {
    setSearchTerm(searchTerm)
    const filtered = services.filter((service) =>
      service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.specialty.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredServices(filtered);
  };

  const handleSuggestionClick = (chip) => {
    setSearchTerm(chip.professionalTitle)
  };


  return (
    <div className="p-4">
      {/* Search Bar aquí */}
      <SearchBar
        placeholder="Buscar servicios"
        data={services}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        dataType="servicio"
      />

      {/* Mostrar sugerencias solo si la búsqueda está vacía */}
      {searchTerm === '' && suggestions.length > 0 && (
        <>
          {/* Agregar título */}
          <Typography variant="h6" style={{ marginBottom: '1rem', marginLeft: '1rem', fontWeight: 'bold' }}>
            Sugerencias para ti
          </Typography>

          <Typography>
            <p
              className="marketName"
              style={{
                display: 'inline',
                margin: 0,
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {services.name}
            </p>
          </Typography>

          <div className="category-grid">
            {suggestions.map((chip) => (
              <CategoryButton
                key={chip.id}
                label={chip.professionalTitle}
                onClick={() => {
                  handleSuggestionClick(chip);
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

function CategoryButton({ label, onClick }) {
  return (
    <button className="category-button" onClick={onClick}>{label}
    </button>
  );
}

export default ServicesPage;
